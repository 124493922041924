import axios from '@axios';
import qs from 'qs';
import _ from 'lodash';

const initialQuery = () => {
    return {
        sort: ['id:asc'],
        filters: {
            occupationPercentage: {
                $between: [0, 100],
            },
            managers: {
                id: {
                    $containsi: '',
                },
            },
            district: {
                id: {
                    $containsi: '',
                },
            },
            country: {
                id: {
                    $containsi: '',
                },
            },
            county: {
                id: {
                    $containsi: '',
                },
            },
            parish: {
                id: {
                    $containsi: '',
                },
            },

            $or: [
                {
                    name: {
                        $containsi: '',
                    },
                },
                {
                    email: {
                        $containsi: '',
                    },
                },

                {
                    district: {
                        name: {
                            $containsi: '',
                        },
                    },
                },
                {
                    county: {
                        name: {
                            $containsi: '',
                        },
                    },
                },
                {
                    parish: {
                        name: {
                            $containsi: '',
                        },
                    },
                },
                {
                    managers: {
                        name: {
                            $containsi: '',
                        },
                    },
                },
            ],
        },
        populate: ['pictures', 'district', 'county', 'parish', 'managers'],
        pagination: {
            page: 1,
            pageSize: 25,
        },
    };
};

export default {
    namespaced: true,
    state: {
        parks: [],
        query: initialQuery(),
        isDeletePark: false,
    },
    getters: {
        getParkList(state) {
            return state.parks;
        },
        getParkQuery(state) {
            return state.query;
        },
        getPark(state) {
            return state.park;
        },
        getIsDeletePark(state) {
            return state.isDeletePark;
        },
    },
    mutations: {
        setParkList(state, parks) {
            state.parks = parks;
        },
        setParkQuery(state, query) {
            state.query = query;
        },
        resetParkQuery(state) {
            state.query = initialQuery();
        },
        setPark(state, parkData) {
            state.parkData = parkData;
        },
        setParkSearch(state, search) {
            state.query.filters.$or[0].name.$containsi = search;
            state.query.filters.$or[1].email.$containsi = search;
            state.query.filters.$or[2].district.name.$containsi = search;
            state.query.filters.$or[3].county.name.$containsi = search;
            state.query.filters.$or[4].parish.name.$containsi = search;
            state.query.filters.$or[5].managers.name.$containsi = search;
        },
        setIsDeletePark(state, bol) {
            state.isDeletePark = bol;
        },
    },
    actions: {
        fetchParkList(ctx) {
            let _query = _.cloneDeep(ctx.state.query);
            delete _query.filters.country.id.$containsi;
            if (!_query.filters.district.id.$containsi) {
                delete _query.filters.district;
            }
            if (!_query.filters.county.id.$containsi) {
                delete _query.filters.county;
            }
            if (!_query.filters.parish.id.$containsi) {
                delete _query.filters.parish;
            }
            if (!_query.filters.managers.id.$containsi) {
                delete _query.filters.managers;
            }
            if (!_query.filters.occupationPercentage.$between) {
                delete _query.filters.occupationPercentage;
            }
            let query = qs.stringify(_query);

            return new Promise((resolve, reject) => {
                axios
                    .get('/parking-lots?' + query)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        fetchPark(ctx, id, query = {}) {
            query.populate = [
                'pictures',
                'country',
                'district',
                'county',
                'parish',
                'managers',
                'managers.role',
                'managers.parkingLots',
                'sections.children',
                'sections.lots',
                'sections.children.lots',
            ];
            return new Promise((resolve, reject) => {
                axios
                    .get(`/parking-lots/${id}`, { params: query })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        updatePark(ctx, data) {
            let query = qs.stringify({
                populate: ['pictures', 'country', 'district', 'county', 'parish', 'managers'],
            });
            return new Promise((resolve, reject) => {
                let _data = _.assign({}, data);
                let id = _data.id;
                delete _data.id;
                _data.pictures = _.get(_data, 'pictures[0].id') ? [_.get(_data, 'pictures[0].id')] : [];
                axios
                    .put(`/parking-lots/${id}?${query}`, { data: _data })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        addPark(ctx, parkData) {
            parkData = _.assign({}, parkData);
            delete parkData.sections;

            return new Promise((resolve, reject) => {
                axios
                    .post('/parking-lots', { data: parkData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deletePark(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/parking-lots/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        deleteManyParks(ctx, query = {}) {
            query = qs.stringify(query);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/parking-lots/many?${query}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        /*         
export function deleteManyItems(query = {}) {
    query = qs.stringify(query);
    return axios.delete(`/items/many?${query}`);
} */
        /*   addSections(ctx, sectionsData) {
            return new Promise((resolve, reject) => {
                axios
                    .post('/parking-lots-sections', { data: sectionsData })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        }, */
    },
};
